import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { getUserInactivityLimit, getUserInactivitySubLimit } from '../../lib/utils';

const timeout = getUserInactivityLimit();
const promptBeforeIdle = getUserInactivitySubLimit();

export default function Timer(props) {
  const { goToHome } = props;
  const [openModal, setOpenModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);

  const onHandleOk = () => {
    setOpenModal(false);
    handleStillHere();
  };

  const onIdle = () => {
    goToHome();
  }

  const onActive = () => {
    //console.log('Active');
  }

  const onPrompt = () => {
    setOpenModal(true);
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500);

    return () => {
      clearInterval(interval);
    }
  })

  const handleStillHere = () => {
    activate();
  }
  
  return (
    <ConfirmModal
      title={'Confirme por favor'}
      message={'¿Sigues allí?'}
      okText={`Sí (${remaining})`}
      isModalOpen={openModal}
      onHandleOk={onHandleOk}
    />
  )
}
