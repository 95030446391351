import ArrowBackIcon from './arrow_back.svg';
import ShoppingCartIcon from './shopping_cart.svg';
import ShoppingCartBlackIcon from './shopping_cart_black.svg';
import CheckCircleIcon from './check_circle.svg';
import StoreIcon from './store.svg';
import CoinsIcon from './coins.svg';
import ArrowGrayIcon from './arrow_cool_down_gray.svg';
import ArrowOrangeIcon from './arrow_cool_down_orange.svg';

export const arrowBackIcon = ArrowBackIcon;
export const shoppingCartIcon = ShoppingCartIcon;
export const shoppingCartIconBlack = ShoppingCartBlackIcon;
export const checkCircleIcon = CheckCircleIcon;
export const storeIcon = StoreIcon;
export const coinsIcon = CoinsIcon;
export const arrowGrayIcon = ArrowGrayIcon;
export const arrowOrangeIcon = ArrowOrangeIcon;