
export const getTotalAmount = (shoppingCart) => {
  let totalAmount = 0;
  shoppingCart.forEach((product) => {
    totalAmount += product.totalOrder;
  });
  return totalAmount;
};

export const getTipTotalAmount = (totalAmount, tipPercentageOption) => {
  return totalAmount * (tipPercentageOption / 100);
};

export const getVersion = () => {
  return process.env.REACT_APP_VERSION;
};

export const getUserInactivityLimit = () => {
  const userInactivityLimit = process.env.REACT_APP_USER_INACTIVITY_LIMIT;
  if (userInactivityLimit) {
    return parseInt(userInactivityLimit, 10);
  }
  return 60000;
};

export const getUserInactivitySubLimit = () => {
  const userInactivitySubLimit = process.env.REACT_APP_USER_INACTIVITY_SUB_LIMIT;
  if (userInactivitySubLimit) {
    return parseInt(userInactivitySubLimit, 10);
  }
  return 10000;
}