import T from './logo/t.svg';
import O from './logo/o.svg';
import L from './logo/l.svg';
import I from './logo/i.svg';
import V from './logo/v.svg';

import LogoTolivBlack from './logo-toliv-black.svg';
import Placeholder from './placeholder.jpg';

export const t = T;
export const o = O;
export const l = L;
export const i = I;
export const v = V;

export const logoTolivBlack = LogoTolivBlack;
export const placeholder = Placeholder;