import { Button, Modal } from 'antd';

export default function ConfirmModal(props) {
  const {
    title = 'Confirme por favor',
    message,
    isModalOpen,
    onHandleCancel,
    onHandleOk,
    okText = 'Sí',
    cancelText = 'No',
  } = props;
  const footer = [];
  if (onHandleCancel) {
    footer.push(<Button
      key="cancel"
      type="default"
      onClick={onHandleCancel}
      size='large'>
      {cancelText}
    </Button>);
  }
  if (onHandleOk) {
    footer.push(<Button
      key="ok"
      type="primary"
      onClick={onHandleOk}
      size='large'>
      {okText}
    </Button>);
  }
  return (
    <Modal
      isModalOpen={isModalOpen}
      title={title}
      open={isModalOpen}
      closable={false}
      footer={footer}
      centered>
      <p>{message}</p>
    </Modal>
  );
}